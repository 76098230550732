import React, { Component } from "react"

/* REST API */
import { parent } from '../../api/Category'
import { list, createCharacteristic } from '../../api/Characteristic'
import { create } from '../../api/Product'

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../helpers'





/*
    Page Create Product
*/
class CreateProduct extends Component {

    constructor() {
        super()

        this.state = {
            parents: [],

            name: "",
            slug: "",
            categoryID: 0,

            price: "",
            salePrice: "",
            barcode: "",
            description: "",

            image: null,
            picture: null,

            metaKeywords: "",
            metaDescription: "",

            gallery: [],
            files: [],

            chars: [],

            characteristics: [],
        }
    }

    componentDidMount = () => {
        this.load()
        this.characteristics()
    }


    /* Load Categories */
    load = () => {
        parent().then(response => {
            if (response.status === 200) {
                this.setState({ parents: response.data })
            }
        })
    }

    /* Load Characteristics */
    characteristics = () => {
        list().then(response => {
            if (response.status === 200) {
                this.setState({ characteristics: response.data })
            }
        })
    }

    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Добавить продукт</p>
                </div>

            </div>
        )
    }



    /* CREATE PRODUCT */
    create = () => {

        const {
            name, slug, categoryID, price, salePrice, barcode, description,
            image, gallery, chars, metaDescription, metaKeywords
        } = this.state

        const { navigate } = this.props

        if (name === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название', showConfirmButton: false, timer: 1500 })
            return
        }

        if (slug === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Key (URL)', showConfirmButton: false, timer: 1500 })
            return
        }

        if (price) {
            if (!utils.isNumeric(price)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (salePrice) {
            if (!utils.isNumeric(salePrice)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        const data = new FormData()
        data.append('name', name)
        data.append('slug', slug)
        data.append('categoryID', categoryID)

        data.append('image', image)

        if (gallery.length > 0) {
            gallery.forEach((picture) => {
                data.append(`gallery`, picture)
            })
        }

        data.append('price', price)
        data.append('salePrice', salePrice)

        data.append('barcode', barcode)
        data.append('description', description)

        data.append('chars', JSON.stringify(chars))

        data.append('metaKeywords', metaKeywords)
        data.append('metaDescription', metaDescription)


        create(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешно создан',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/product')
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }



    /* Set Name */
    setName = event => {
        this.setState({ name: event.target.value })
        const slug = utils.translit(event.target.value)
        this.setState({ slug })
    }


    /* Upload Image */
    upload = event => {
        let file = event.target.files[0]

        if (file) {
            this.setState({ image: file, picture: URL.createObjectURL(file) })
        }
    }

    /* Upload to Gallery */
    uploadGallery = event => {

        const { gallery, files } = this.state

        for (let file of event.target.files) {
            gallery.push(file)
            files.push(URL.createObjectURL(file))
        }

        this.setState({ files, gallery })
    }


    /* Remove from gallery */
    removeGallery = index => {
        const { gallery, files } = this.state

        gallery.splice(index, 1)
        files.splice(index, 1)

        this.setState({ files, gallery })
    }


    /* Add Characteristics */
    addCharacteristics = () => {

        const { characteristics } = this.state

        let html = `<option value={0}>Не выбран</option>`

        if (characteristics.length > 0) {
            characteristics.map(item => {
                html += `<option value="${item.id}">${item.name} (${item.unit})</option>`
                return ''
            })
        }

        Swal.fire({
            title: 'Добавить характеристики',
            html: `
                <div class="swal2-content-box">
                    <p class="swal2-content-select-label">Характеристика</p>
                    <select id="create-select" class="swal2-content-select">${html}</select>
                    <input id="create-value" class="swal2-input" placeholder="Введите значение">
                </div>
            `,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Сохранить',
            denyButtonText: `Создать`,
            cancelButtonText: 'Отмена',
            denyButtonColor: '#009bd4',
            confirmButtonColor: '#000',
            reverseButtons: true,
            preConfirm: () => {
                return {
                    id: document.getElementById('create-select').value,
                    value: document.getElementById('create-value').value,
                }
            }

        }).then(result => {

            if (result.isConfirmed) {
                const { chars } = this.state
                chars.push({ id: result.value.id, value: result.value.value })
                this.setState({ chars })
            }

            if (result.isDenied) {
                Swal.fire({
                    title: 'Создать характеристику',
                    html:
                        `<input id="create-name" class="swal2-input" placeholder="Название">` +
                        `<input id="create-unit" class="swal2-input" placeholder="Единица">`,
                    confirmButtonColor: '#000',
                    confirmButtonText: 'Создать',
                    preConfirm: () => {
                        return {
                            name: document.getElementById('create-name').value,
                            unit: document.getElementById('create-unit').value,
                        }
                    }
                }).then((created) => {
                    if (created.isConfirmed) {

                        const { characteristics } = this.state

                        createCharacteristic(created.value).then(response => {
                            if (response.status === 200) {

                                characteristics.push(response.data)
                                this.setState({ characteristics })

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Характеристика успешна создана',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    didClose: () => this.addCharacteristics()
                                })

                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Что-то пошло не так! Попробуйте позднее',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        }).catch(() => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Что-то пошло не так! Попробуйте позднее',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        })
                    }
                })
            }
        })
    }


    /* Get Characteristics */
    getCharacteristics = id => {
        const { characteristics } = this.state

        const index = characteristics.findIndex(e => parseInt(e.id) === parseInt(id))

        if (index > -1) {
            return { name: characteristics[index].name, unit: characteristics[index].unit }
        }

        return { name: '', unit: '' }
    }


    /* Remove Characteristics */
    removeCharacteristic = index => {
        const { chars } = this.state
        chars.splice(index, 1)
        this.setState({ chars })
    }   


    render = () => {

        const { name, slug, parents, price, salePrice, barcode, description, picture, metaDescription, metaKeywords, files, chars } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">
                    <div className="content-form-box">

                        <div className="content-form-half">
                            <p>Название продукта</p>
                            <input type="text" value={name} onChange={event => this.setName(event)} placeholder="Введите название" />

                            <p>Key (URL)</p>
                            <input type="text" value={slug} onChange={event => this.setState({ slug: event.target.value })} placeholder="Введите Key" />

                            <p>Категория</p>
                            <select onChange={event => this.setState({ categoryID: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {parents.map((item, index) =>
                                    <option key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                            <p>Цена</p>
                            <input type="text" value={price} onChange={event => this.setState({ price: event.target.value })} placeholder="Введите цену (₸)" />

                            <p>Скидочная цена</p>
                            <input type="text" value={salePrice} onChange={event => this.setState({ salePrice: event.target.value })} placeholder="Введите скидочную цену (₸)" />

                            <p>Штрихкод</p>
                            <input type="text" value={barcode} onChange={event => this.setState({ barcode: event.target.value })} placeholder="Введите Штрих код" />

                            <p>Описание</p>
                            <textarea value={description} onChange={event => this.setState({ description: event.target.value })} placeholder="Введите Описание"></textarea>

                        </div>

                        <div className="content-form-half">
                            <p>Основная картинка</p>

                            <div className="content-gallery-box">
                                {picture
                                    ?
                                    <div className="content-gallery-item">
                                        <img src={picture} alt="Gallery" />
                                        <div onClick={() => this.setState({ picture: null, image: null })} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                    :
                                    <label className="content-gallery-file">
                                        <input multiple={true} onChange={event => this.upload(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                        <div className="content-gallery-button">
                                            <img src="/pictures/image-upload.png" alt="Add" />
                                        </div>
                                    </label>
                                }
                            </div>


                            <p>Галерея</p>

                            <div className="content-gallery-box">

                                <label className="content-gallery-file">
                                    <input multiple={true} onChange={event => this.uploadGallery(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                    <div className="content-gallery-button">
                                        <img src="/pictures/image.png" alt="Add" />
                                    </div>
                                </label>

                                {files.map((item, index) =>
                                    <div key={`${index}`} className="content-gallery-item">
                                        <img src={item} alt="Gallery" />
                                        <div onClick={() => this.removeGallery(index)} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                )}

                            </div>


                            <p>Характеристики</p>

                            <div className="characteristics">
                                {chars.length === 0 && <div className="content-empty-characteristics">Характеристики отсутсвуют</div>}
                                {chars.map((item, index) => {
                                    let charInfo = this.getCharacteristics(item.id)
                                    return (
                                        <div key={`${index}`} className="content-characteristics-item">
                                            <div className="content-characteristics-item-name">{charInfo.name} ({charInfo.unit})</div>
                                            <div className="content-characteristics-item-value">{item.value}</div>
                                            <div onClick={() => this.removeCharacteristic(index)} className="content-characteristics-item-remove">
                                                <img alt="Minus" src="/pictures/minus.png" />
                                            </div>
                                        </div>
                                    )
                                })}
                                <div onClick={() => this.addCharacteristics()} className="content-characteristics-button">Добавить</div>
                            </div>


                            <h2>Для SEO</h2>

                            <p>Meta Keywords</p>
                            <textarea value={metaKeywords} onChange={event => this.setState({ metaKeywords: event.target.value })} placeholder="Введите Meta Keywords"></textarea>

                            <p>Meta Description</p>
                            <textarea value={metaDescription} onChange={event => this.setState({ metaDescription: event.target.value })} placeholder="Введите Meta Description"></textarea>

                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateProduct)