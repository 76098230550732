import React from 'react'
import ReactDOM from 'react-dom/client'

/* CSS styles */
import './design/css/app.css'
import './design/css/login.css'
import './design/css/layout.css'
import './design/css/pagination.css'
import './design/css/menu.css'
import './design/css/header.css'
import './design/css/order.css'
import './design/css/dashboard.css'


import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

reportWebVitals()