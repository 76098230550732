import React, { Component } from "react"

/* REST API */
import { parent } from '../../api/Category'
import { list, createCharacteristic } from '../../api/Characteristic'
import { view, update, removeImage, uploadImage, uploadGallery, removeGallery, addCharacteristic, removeCharacteristic } from '../../api/Product'

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Constants */
import { env } from "../../constants"

/* Switch */
import Switch from "react-switch"




/*
    Page Edit Product
*/
class EditProduct extends Component {

    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            parents: [],

            name: "",
            slug: "",
            categoryID: 0,

            price: "",
            salePrice: "",
            barcode: "",
            description: "",

            image: null,

            metaKeywords: "",
            metaDescription: "",

            gallery: [],

            chars: [],

            characteristics: [],

            isTop: false,
            isNew: false
        }
    }

    componentDidMount = () => {
        this.product()
        this.load()
        this.characteristics()
    }


    /* Load Categories */
    load = () => {
        parent().then(response => {
            if (response.status === 200) {
                this.setState({ parents: response.data })
            }
        })
    }

    /* Load Characteristics */
    characteristics = () => {
        list().then(response => {
            if (response.status === 200) {
                this.setState({ characteristics: response.data })
            }
        })
    }

    /* Load Products */
    product = () => {
        const { id } = this.state

        view({ id }).then(response => {
            if (response.status === 200) {

                const data = response.data

                this.setState({
                    name: data.name,
                    slug: data.slug,
                    categoryID: data.categoryID,
                    price: data.price,
                    salePrice: data.salePrice,
                    barcode: data.barcode,
                    description: data.description,
                    image: data.image,
                    gallery: data.images ? data.images : [],
                    chars: data.characteristics ? data.characteristics : [],
                    metaKeywords: data.metaKeywords,
                    metaDescription: data.metaDescription,
                    isNew: parseInt(data.isNew) === 1 ? true : false,
                    isTop: parseInt(data.isTop) === 1 ? true : false,
                })
            }
        })
    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Редактировать продукт</p>
                </div>

            </div>
        )
    }



    /* CREATE PRODUCT */
    create = () => {

        const {
            id, name, slug, categoryID, price, salePrice, barcode, description,
            metaDescription, metaKeywords, isNew, isTop
        } = this.state

        const { navigate } = this.props

        if (name === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название', showConfirmButton: false, timer: 1500 })
            return
        }

        if (slug === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Key (URL)', showConfirmButton: false, timer: 1500 })
            return
        }

        if (price) {
            if (!utils.isNumeric(price)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (salePrice) {
            if (!utils.isNumeric(salePrice)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        const data = {
            id, name, slug, categoryID, price, salePrice, barcode, description, metaKeywords, metaDescription, isNew: isNew ? 1 : 0, isTop: isTop ? 1 : 0
        }


        update(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешно сохранен',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/product')
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }



    /* Set Name */
    setName = event => {
        this.setState({ name: event.target.value })
        const slug = utils.translit(event.target.value)
        this.setState({ slug })
    }


    /* Upload Image */
    upload = event => {

        const { id } = this.state
        let file = event.target.files[0]

        if (file) {

            const data = new FormData()
            data.append('id', id)
            data.append('image', file)

            uploadImage(data).then(response => {
                if (response.status === 200) {
                    this.setState({ image: response.data.image })
                }
            })
        }
    }


    /* Upload to Gallery */
    uploadGallery = event => {

        const { id, gallery } = this.state

        for (let file of event.target.files) {

            const data = new FormData()
            data.append('id', id)
            data.append('image', file)

            uploadGallery(data).then(response => {
                if (response.status === 200) {
                    gallery.push({ id: response.data.id, image: response.data.image })
                    this.setState({ gallery })
                }
            })
        }
    }


    /* Remove from gallery */
    removeGallery = (item, index) => {
        removeGallery({ id: item.id }).then(response => {
            if (response.status === 200) {
                const { gallery } = this.state
                gallery.splice(index, 1)
                this.setState({ gallery })
            }
        })
    }


    /* Add Characteristics */
    addCharacteristics = () => {

        const { characteristics, id } = this.state

        let html = `<option value={0}>Не выбран</option>`

        if (characteristics.length > 0) {
            characteristics.map(item => {
                html += `<option value="${item.id}">${item.name} (${item.unit})</option>`
                return ''
            })
        }

        Swal.fire({
            title: 'Добавить характеристики',
            html: `
                <div class="swal2-content-box">
                    <p class="swal2-content-select-label">Характеристика</p>
                    <select id="create-select" class="swal2-content-select">${html}</select>
                    <input id="create-value" class="swal2-input" placeholder="Введите значение">
                </div>
            `,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Сохранить',
            denyButtonText: `Создать`,
            cancelButtonText: 'Отмена',
            denyButtonColor: '#009bd4',
            confirmButtonColor: '#000',
            reverseButtons: true,
            preConfirm: () => {
                return {
                    id: document.getElementById('create-select').value,
                    value: document.getElementById('create-value').value,
                }
            }

        }).then(result => {

            if (result.isConfirmed) {

                addCharacteristic({ productID: id, id: result.value.id, value: result.value.value }).then(response => {
                    if (response.status === 200) {
                        const { chars } = this.state
                        chars.push({ id: result.value.id, value: result.value.value })
                        this.setState({ chars })
                    }
                })

            }

            if (result.isDenied) {
                Swal.fire({
                    title: 'Создать характеристику',
                    html:
                        `<input id="create-name" class="swal2-input" placeholder="Название">` +
                        `<input id="create-unit" class="swal2-input" placeholder="Единица">`,
                    confirmButtonColor: '#000',
                    confirmButtonText: 'Создать',
                    preConfirm: () => {
                        return {
                            name: document.getElementById('create-name').value,
                            unit: document.getElementById('create-unit').value,
                        }
                    }
                }).then((created) => {
                    if (created.isConfirmed) {

                        const { characteristics } = this.state

                        createCharacteristic(created.value).then(response => {
                            if (response.status === 200) {

                                characteristics.push(response.data)
                                this.setState({ characteristics })

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Характеристика успешна создана',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    didClose: () => this.addCharacteristics()
                                })

                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Что-то пошло не так! Попробуйте позднее',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        }).catch(() => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Что-то пошло не так! Попробуйте позднее',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        })
                    }
                })
            }
        })
    }


    /* Get Characteristics */
    getCharacteristics = id => {
        const { characteristics } = this.state

        const index = characteristics.findIndex(e => parseInt(e.id) === parseInt(id))

        if (index > -1) {
            return { name: characteristics[index].name, unit: characteristics[index].unit }
        }

        return { name: '', unit: '' }
    }


    /* Remove Characteristics */
    removeCharacteristic = (item, index) => {
        const { chars, id } = this.state

        removeCharacteristic({ productID: id, id: item.id }).then(response => {
            if (response.status === 200) {
                chars.splice(index, 1)
                this.setState({ chars })
            }
        })
    }


    /* Remove Image */
    removeImage = () => {
        const { id } = this.state

        removeImage({ id }).then(response => {
            if (response.status === 200) {
                this.setState({ image: null })
            }
        })
    }


    render = () => {

        const { name, slug, parents, categoryID, price, salePrice, barcode, description, image, metaDescription, metaKeywords, gallery, chars, isNew, isTop } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">
                    <div className="content-form-box">

                        <div className="content-form-half">
                            <p>Название продукта</p>
                            <input type="text" value={name} onChange={event => this.setName(event)} placeholder="Введите название" />

                            <p>Key (URL)</p>
                            <input type="text" value={slug} onChange={event => this.setState({ slug: event.target.value })} placeholder="Введите Key" />

                            <p>Категория</p>
                            <select onChange={event => this.setState({ categoryID: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {parents.map((item, index) =>
                                    <option selected={parseInt(categoryID) === parseInt(item.id)} key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                            <p>Цена</p>
                            <input type="text" value={price} onChange={event => this.setState({ price: event.target.value })} placeholder="Введите цену (₸)" />

                            <p>Скидочная цена</p>
                            <input type="text" value={salePrice} onChange={event => this.setState({ salePrice: event.target.value })} placeholder="Введите скидочную цену (₸)" />

                            <p>Штрихкод</p>
                            <input type="text" value={barcode} onChange={event => this.setState({ barcode: event.target.value })} placeholder="Введите Штрих код" />

                            <p>Описание</p>
                            <textarea value={description} onChange={event => this.setState({ description: event.target.value })} placeholder="Введите Описание"></textarea>


                            <div className="switch">
                                <p>Новинка</p>
                                <Switch
                                    onChange={value => this.setState({ isNew: value })}
                                    checked={isNew}
                                    onColor="#b1e17a"
                                    onHandleColor="#8bc34a"
                                    offHandleColor="#fff"
                                    offColor="#d7d7d7"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    height={20}
                                    width={40}
                                />
                            </div>

                            <div className="switch">
                                <p>Топ продукт</p>
                                <Switch
                                    onChange={value => this.setState({ isTop: value })}
                                    checked={isTop}
                                    onColor="#b1e17a"
                                    onHandleColor="#8bc34a"
                                    offHandleColor="#fff"
                                    offColor="#d7d7d7"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    height={20}
                                    width={40}
                                />
                            </div>

                        </div>

                        <div className="content-form-half">
                            <p>Основная картинка</p>

                            <div className="content-gallery-box">
                                {image
                                    ?
                                    <div className="content-gallery-item">
                                        <img src={`${env.mediapoint}products/${image}`} alt="Gallery" />
                                        <div onClick={() => this.removeImage()} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                    :
                                    <label className="content-gallery-file">
                                        <input multiple={true} onChange={event => this.upload(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                        <div className="content-gallery-button">
                                            <img src="/pictures/image-upload.png" alt="Add" />
                                        </div>
                                    </label>
                                }
                            </div>


                            <p>Галерея</p>

                            <div className="content-gallery-box">

                                <label className="content-gallery-file">
                                    <input multiple={true} onChange={event => this.uploadGallery(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                    <div className="content-gallery-button">
                                        <img src="/pictures/image.png" alt="Add" />
                                    </div>
                                </label>

                                {gallery.map((item, index) =>
                                    <div key={`${index}`} className="content-gallery-item">
                                        <img src={`${env.mediapoint}products/${item.image}`} alt="Gallery" />
                                        <div onClick={() => this.removeGallery(item, index)} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                )}

                            </div>


                            <p>Характеристики</p>

                            <div className="characteristics">
                                {chars.length === 0 && <div className="content-empty-characteristics">Характеристики отсутсвуют</div>}
                                {chars.map((item, index) => {
                                    let charInfo = this.getCharacteristics(item.id)
                                    return (
                                        <div key={`${index}`} className="content-characteristics-item">
                                            <div className="content-characteristics-item-name">{charInfo.name} ({charInfo.unit})</div>
                                            <div className="content-characteristics-item-value">{item.value}</div>
                                            <div onClick={() => this.removeCharacteristic(item, index)} className="content-characteristics-item-remove">
                                                <img alt="Minus" src="/pictures/minus.png" />
                                            </div>
                                        </div>
                                    )
                                })}
                                <div onClick={() => this.addCharacteristics()} className="content-characteristics-button">Добавить</div>
                            </div>


                            <h2>Для SEO</h2>

                            <p>Meta Keywords</p>
                            <textarea value={metaKeywords} onChange={event => this.setState({ metaKeywords: event.target.value })} placeholder="Введите Meta Keywords"></textarea>

                            <p>Meta Description</p>
                            <textarea value={metaDescription} onChange={event => this.setState({ metaDescription: event.target.value })} placeholder="Введите Meta Description"></textarea>

                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Сохранить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(EditProduct)